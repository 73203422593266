import React from 'react';
import { makeStyles } from 'tss-react/mui';
import {
    Timesheet,
    TimesheetDay,
    Summary,
    TIMESHEET_SETTING,
    OtherTimeReportedHoursForWeek,
} from '../typings';
import Typography from '@mui/material/Typography';
import NormalTimesheet from './NormalTimesheet';
import TextField from 'components/interface/textField/TextField';
import TriStateSwitch from 'components/interface/triStateSwitch/TriStateSwitch';
import StartStopTimesheet from './StartStopTimesheet';
import Warnings from './Warnings';
import { AppType } from 'api/Api';
import { APP_TYPE } from 'utils/env';
import { ProfessionIdentifier } from 'typings/titles';

const useStyles = makeStyles()(theme => ({
    base: {
        marginBottom: theme.spacing(4),
    },
    title: {
        marginBottom: theme.spacing(1),
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        columnGap: theme.spacing(2),
    },
    comment: {
        marginBottom: theme.spacing(4),
    },
}));

export interface TimesheetProps {
    timesheetSetting: TIMESHEET_SETTING;
    timesheet: Timesheet;
    summary: Summary;
    canSubmit: boolean;
    success: boolean;
    loading: boolean;
    enableSickHours: boolean;
    otherTimeReportedHoursForWeek: OtherTimeReportedHoursForWeek;
    onEnableSickHours: (value: boolean) => void;
    onUpdateTimesheetDay: (
        updateFunc: (day: TimesheetDay) => TimesheetDay,
        index: number
    ) => void;
    onNotesFieldChange: (value: string) => void;
    useSplitTextfields: boolean;
    setUseSplitTextfields?: (value: boolean) => void;
}

export default function TimesheetComponent({
    timesheetSetting,
    timesheet,
    canSubmit,
    success,
    loading,
    enableSickHours,
    otherTimeReportedHoursForWeek,
    onEnableSickHours,
    onNotesFieldChange,
    useSplitTextfields,
    setUseSplitTextfields,
    ...rest
}: TimesheetProps): JSX.Element {
    const { classes } = useStyles();
    const isSSK =
        timesheet?.template?.profession?.identifier ===
        ProfessionIdentifier.Nurse;

    const renderComment = () => {
        return (
            <div className={classes.comment}>
                <TextField
                    id='notesTimesheet'
                    label='Kommentar'
                    multiline
                    value={timesheet?.notes || ''}
                    onChange={onNotesFieldChange}
                    variant='outlined'
                    disabled={!canSubmit}
                    fullWidth
                />
            </div>
        );
    };

    return (
        <div className={classes.base}>
            <div className={classes.title}>
                <Typography variant='h6' color='textPrimary'>
                    <b> Tidrapport</b>
                </Typography>
                <div className={classes.actions}>
                     {/* @ts-ignore */}
                    {AppType === APP_TYPE.TOOLS &&
                        timesheetSetting === TIMESHEET_SETTING.NORMAL &&
                        setUseSplitTextfields &&
                        canSubmit && (
                            <TriStateSwitch
                                label='Ange minuter separat'
                                value={useSplitTextfields || false}
                                onChange={v => setUseSplitTextfields(!!v)}
                                disabled={!canSubmit}
                                disableNull
                                asText
                            />
                        )}
                    {isSSK && canSubmit && (
                        <TriStateSwitch
                            label='Rapporera sjuktimmar'
                            value={enableSickHours || false}
                            onChange={v => onEnableSickHours(!!v)}
                            disabled={!canSubmit}
                            disableNull
                            asText
                        />
                    )}
                </div>
            </div>
             {/* @ts-ignore */}
            {AppType === APP_TYPE.TOOLS ? (
                <Warnings
                    timesheet={timesheet}
                    timesheetSetting={timesheetSetting}
                    show40hWarning={true}
                    showRestWarning={
                        timesheetSetting === TIMESHEET_SETTING.START_STOP_TIME
                    }
                    otherTimeReportedHoursForWeek={
                        otherTimeReportedHoursForWeek
                    }
                />
            ) : null}
            {timesheetSetting === TIMESHEET_SETTING.START_STOP_TIME ? (
                <StartStopTimesheet
                    {...rest}
                    timesheet={timesheet}
                    canSubmit={canSubmit}
                    loading={loading}
                    enableSickHours={enableSickHours}
                />
            ) : (
                <NormalTimesheet
                    {...rest}
                    timesheet={timesheet}
                    canSubmit={canSubmit}
                    loading={loading}
                    enableSickHours={enableSickHours}
                    useSplitTextfields={useSplitTextfields}
                />
            )}
            {timesheet.template.hasComment ? renderComment() : undefined}
        </div>
    );
}
