import React, { useState, useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { makeStyles } from 'tss-react/mui';
import ClearIcon from '@mui/icons-material/Clear';
import Button from 'components/interface/Button/Button';
import Typography from '@mui/material/Typography';
import AnimateHeight from 'react-animate-height';
import { setCookie } from 'utils/cookies';
import useInstallerStore from 'store/useInstallerStore';
import ReactGA from 'react-ga';

const useStyles = makeStyles()((theme, _params, _classes) => ({
    root: {
        width: '100%',
        height: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.secondary[500],
    },
    content: {
        width: '100%',
        maxWidth: '1414px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1, 0),
        [theme.breakpoints.down(1414)]: {
            padding: theme.spacing(1, 2),
        },
    },
    icon: {
        color: theme.palette.primaryDark[500],
    },
    text: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        color: theme.palette.primaryDark[500],
        padding: theme.spacing(0, 2),
    },
    textTitle: {
        fontWeight: '800',
    },
    textBody: {
        lineHeight: '1.2',
    },
    button: {
        borderRadius: '40px',
        width: '156px',
        boxShadow: 'none',
    },
}));

interface Props {
    setBannerIsOpen?: (v: boolean) => void;
}

export default function InstallBanner({ setBannerIsOpen }: Props) {
    const { classes } = useStyles();
    const [shrink, setShrink] = useState(true);
    const { show, deferredPrompt } = useInstallerStore(
        state => ({
            show: state.show,
            deferredPrompt: state.deferredPrompt,
        }),
        shallow
    );
    const setShow = useInstallerStore(state => state.setShow);
    const setAppInstalled = useInstallerStore(state => state.setAppInstalled);
    const setDeferredPrompt = useInstallerStore(
        state => state.setDeferredPrompt
    );

    useEffect(() => {
        setShrink(false);
        window.addEventListener('appinstalled', installed);
        return () => {
            window.removeEventListener('appinstalled', installed);
        };
    }, []);

    useEffect(() => {
        if (setBannerIsOpen) {
            setBannerIsOpen(show);
        }
    }, [show]);

    const installed = () => {
        setDeferredPrompt(null);
        // This fires after onbeforinstallprompt OR after manual add to homescreen.
        ReactGA.event({
            category: 'PWA Install',
            action: 'Installed',
            label: 'banner',
        });
        setShrink(true);
    };

    const handleInstall = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice
                .then(function (choiceResult: any) {
                    setDeferredPrompt(null);
                    if (choiceResult.outcome === 'accepted') {
                        ReactGA.event({
                            category: 'PWA Install',
                            action: 'Accepted',
                            label: 'banner',
                        });
                        setShrink(true);
                        setAppInstalled(true);
                    } else {
                        ReactGA.event({
                            category: 'PWA Install',
                            action: 'Declined',
                            label: 'banner',
                        });
                    }
                })
                .catch((_installError: any) => {
                    setDeferredPrompt(null);
                    ReactGA.event({
                        category: 'PWA Install',
                        action: 'Error',
                        label: 'banner',
                    });
                    setShrink(true);
                });
        }
    };

    const handleReject = () => {
        ReactGA.event({
            category: 'PWA Install',
            action: 'Dismissed',
            label: 'banner',
        });
        setShrink(true);
        setCookie('install_dismissed', true, 30);
    };

    const renderBanner = () => (
        <AnimateHeight
            duration={500}
            height={shrink ? 0 : 'auto'}
            onAnimationEnd={() => {
                if (shrink) {
                    setShow(false);
                }
            }}
            style={{ width: '100%' }}
            contentClassName={classes.root}>
            <div className={classes.content}>
                <ClearIcon className={classes.icon} onClick={handleReject} />
                <div className={classes.text}>
                    <Typography variant='body1' className={classes.textTitle}>
                        Hej!
                    </Typography>
                    <Typography variant='caption' className={classes.textBody}>
                        Du kan installera appen för en smidigare
                        tidrapportering.
                    </Typography>
                </div>
                <Button
                    className={classes.button}
                    variant='contained'
                    disableRipple
                    color='primaryDark'
                    onClick={handleInstall}>
                    INSTALLERA
                </Button>
            </div>
        </AnimateHeight>
    );

    return <>{show ? renderBanner() : undefined}</>;
}
