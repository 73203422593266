import React from 'react';
import { makeStyles } from 'tss-react/mui';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import { Bars } from 'react-loader-spinner';

const useStyles = makeStyles()(theme => ({
    base: {
        borderRadius: '500px',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textTransform: 'none',
    },
    upperCase: {
        textTransform: 'uppercase',
    },
    noShadow: {
        boxShadow: 'none !important',
    },
    white: {
        backgroundColor: '#fff',
    },
}));

interface Props extends Omit<ButtonProps, 'color' | 'variant'> {
    classes?: any;
    color?:
        | 'default'
        | 'gray' // note that we use gray instead of grey here, this is because we can't override the default grey.
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'bluegreen'
        | 'primaryDark'
        | 'yellow'
        | 'error';
    variant?: 'text' | 'outlined' | 'contained';
    noShadow?: boolean;
    upperCase?: boolean;
    loading?: boolean;
    white?: boolean;
}

// eslint-disable-next-line react/display-name
const Button = React.forwardRef<any, Props>(
    (
        {
            classes: classesprops,
            color,
            variant,
            noShadow = true,
            upperCase,
            loading,
            children,
            onClick,
            white,
            ...others
        }: Props,
        ref
    ) => {
        if (!variant) {
            variant = 'text';
        }
        if (!color || color === 'default') {
            color = 'gray';
        }
        const { classes, cx } = useStyles();
        const mixedClass = { ...classesprops };
        mixedClass.root = cx(
            classes.base,
            {
                [classes.noShadow]: noShadow,
                [classes.upperCase]: upperCase,
                [classes.white]: variant === 'outlined' && white,
            },
            mixedClass?.root
        );

        return (
            <MuiButton
                ref={ref}
                {...others}
                color={color}
                variant={variant}
                classes={mixedClass}
                onClick={loading ? undefined : onClick}>
                {loading ? (
                    <Bars
                        height='20'
                        width='20'
                        color={variant === 'contained' ? 'white' : 'black'}
                    />
                ) : (
                    children
                )}
            </MuiButton>
        );
    }
);

export default Button;
