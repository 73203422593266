import React from 'react';
import Api from 'api/Api';
import useMeStore from 'store/useMeStore';
import { Logout } from 'store/logout';

export default function useLogout() {
    const isLoggedIn = useMeStore(state => state.isLoggedIn);
    const token = useMeStore(state => state.token);

    const logout = async () => {
        if (token && isLoggedIn) {
            try {
                // The API call is only used for logging the logout on the server at the moment.
                await Api.user.logout(token);
            } catch (err) {
                console.log(err);
            }
        }
        // If the user in not logged in and tries to logout, it means that there is a stuck state
        // Refresh the page to fix it.
        if (!isLoggedIn) {
            setTimeout(() => {
                window.onbeforeunload = undefined;
                window.location.reload();
            }, 50);
        }
        Logout()
    };

    return { logout };
}
