import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { isInStandaloneMode } from 'utils/utils_legacy';

const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        if (process.env.REACT_APP_BUILD_VERSION) {
            ReactGA.set({ dimension1: process.env.REACT_APP_BUILD_VERSION });
        }
        ReactGA.set({ dimension2: isInStandaloneMode() ? 'True' : 'False' });
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);
};

export default usePageTracking;
