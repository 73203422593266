import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { shallow } from 'zustand/shallow';
import Wrapper from '../Wrapper';
import { LoginWithBankID } from './BankID';
import NormalLogin from './Normal';
import useLoginStore, { LOGIN_METHOD } from './store/useLoginStore';

const useStyles = makeStyles<void, 'loading'>()((_theme, _params, classes) => ({
    body: {
        maxWidth: '350px',
        width: '100%',
        flexShrink: 0,
        opacity: 1,
        transition: 'opacity 2.0s ease-in-out',
        [`&.${classes.loading}`]: {
            transition: 'opacity 1.0s ease-in-out',
            opacity: 0,
        },
    },
    loading: {},
}));

interface Props {
    isMobile: boolean;
    redirectedFrom: string | null;
}

export default function LoginComponent({ isMobile, redirectedFrom }: Props) {
    const { classes, cx } = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [loginMethodAlreadyChosen, setLoginMethodAlreadyChosen] =
        React.useState(false); // this is only so we don't have to repeat the selection step after choosing an option
    const [method, setMethod] = useLoginStore(
        state => [state.method, state.setMethod],
        shallow
    );

    const handleSetMethod = (v: LOGIN_METHOD) => {
        setLoginMethodAlreadyChosen(true);
        setMethod(v);
    };

    const renderLogin = () => {
        switch (method) {
            case LOGIN_METHOD.BANKID:
                return (
                    <LoginWithBankID
                        setLoading={setLoading}
                        redirectedFrom={redirectedFrom}
                        setMethod={handleSetMethod}
                        loginMethodAlreadyChosen={loginMethodAlreadyChosen}
                    />
                );
            default:
                return (
                    <NormalLogin
                        setLoading={setLoading}
                        redirectedFrom={redirectedFrom}
                        setMethod={handleSetMethod}
                    />
                );
        }
    };

    return (
        <Wrapper loading={loading} isMobile={isMobile}>
            <div
                className={cx(classes.body, {
                    [classes.loading]: loading,
                })}>
                {renderLogin()}
            </div>
        </Wrapper>
    );
}
