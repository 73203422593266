import React from 'react';
import HTTPStatusInterceptor from './HTTPStatusInterceptor';
import PageNotFound from 'pages/ErrorPages/PageNotFound';
import { authData } from 'Routes';

interface Props {
    children: JSX.Element;
    auth: () => authData;
}

export const RequiresLoggedOut = ({ children, auth }: Props) => {
    const { isAuthed } = auth();
    return !isAuthed ? (
        <HTTPStatusInterceptor>{children}</HTTPStatusInterceptor>
    ) : (
        <PageNotFound />
    );
};
