import React, { useState, useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { makeStyles } from 'tss-react/mui';
import Button from 'components/interface/Button/Button';
import Typography from '@mui/material/Typography';
import AnimateHeight from 'react-animate-height';
import useBuildVersionStore from 'store/useBuildVersionStore';
import ReactGA from 'react-ga';

const useStyles = makeStyles()((theme, _params, _classes) => ({
    root: {
        width: '100%',
        height: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.primaryLight[500],
    },
    content: {
        width: '100%',
        maxWidth: '1414px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1, 0),
        [theme.breakpoints.down(1414)]: {
            padding: theme.spacing(1, 2),
        },
    },
    text: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        color: theme.palette.primaryDark[500],
    },
    textTitle: {
        fontWeight: '800',
    },
    textBody: {
        lineHeight: '1.2',
    },
    button: {
        borderRadius: '40px',
        width: '156px',
        boxShadow: 'none',
    },
}));

interface Props {
    hide: boolean;
    setBannerIsOpen?: (v: boolean) => void;
}

export default function UpdateBanner({ hide, setBannerIsOpen }: Props) {
    const { classes } = useStyles();
    const [show, setShow] = useState(false);
    const [shrink, setShrink] = useState(true);
    const { updateAvailable, timestamp } = useBuildVersionStore(
        state => ({
            updateAvailable: state.updateAvailable,
            timestamp: state.timestamp,
        }),
        shallow
    );
    const setDoInstall = useBuildVersionStore(state => state.setDoInstall);

    useEffect(() => {
        if (setBannerIsOpen) {
            setBannerIsOpen(show);
        }
    }, [show]);

    useEffect(() => {
        if (updateAvailable) {
            handleShowBanner();
        } else if (show) {
            handleHideBanner();
        }
    }, [updateAvailable, timestamp]);

    const handleShowBanner = () => {
        setShow(true);
        setShrink(false);
        ReactGA.event({
            category: 'PWA Update',
            action: 'Available',
            label: 'banner',
        });
    };

    const handleHideBanner = () => {
        setShow(false);
        setShrink(true);
    };

    const handleUpdate = () => {
        if (updateAvailable) {
            setDoInstall(true);
        }
    };

    const renderBanner = () => (
        <AnimateHeight
            duration={500}
            height={shrink ? 0 : 'auto'}
            onAnimationEnd={() => {
                if (shrink) {
                    setShow(false);
                }
            }}
            style={{ width: '100%' }}
            contentClassName={classes.root}>
            <div className={classes.content}>
                <div className={classes.text}>
                    <Typography variant='body1' className={classes.textTitle}>
                        Hej!
                    </Typography>
                    <Typography variant='caption' className={classes.textBody}>
                        Det finns en ny uppdatering, klicka för att uppdatera.
                    </Typography>
                </div>
                <Button
                    className={classes.button}
                    variant='contained'
                    disableRipple
                    color='primaryDark'
                    onClick={handleUpdate}>
                    UPPDATERA
                </Button>
            </div>
        </AnimateHeight>
    );

    return <>{show && !hide ? renderBanner() : undefined}</>;
}
