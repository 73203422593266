import React from 'react';
import { Redirect } from 'react-router-dom';
import HTTPStatusInterceptor from './HTTPStatusInterceptor';
import { useLocation } from 'react-router-dom';
import { authData, LocationState } from 'Routes';

interface Props {
    children: JSX.Element;
    auth: () => authData;
}

export const RequiresAuth = ({ children, auth }: Props) => {
    const location = useLocation<LocationState>();
    const { isAuthed, isLoggedIn } = auth();
    return isAuthed ? (
        <HTTPStatusInterceptor>{children}</HTTPStatusInterceptor>
    ) : (
        <Redirect
            exact
            to={{
                pathname: '/logga-in',
                state: {
                    // if we are not authed but logged in, we dont want to set the redirect value, as it will result in an endless redirect loop
                    redirectedFrom: isLoggedIn ? null : location.pathname,
                },
            }}
        />
    );
};
