import React from 'react';
import { getCookie } from 'utils/cookies';
import { isInStandaloneMode } from 'utils/utils_legacy';
import { LoadingScreen } from 'components/interface/Loading';
import NotificationManager from 'components/notificationManager/notificationManager';
import ReactGA from 'react-ga';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import useAppVersion from 'components/hooks/useAppVersion';
import useMeStore from 'store/useMeStore';
import useBuildVersionStore from 'store/useBuildVersionStore';
import useInstallerStore from 'store/useInstallerStore';
import usePageTracking from 'components/hooks/usePageTracking';
import Theme from './Theme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { sv } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import './App.css';

interface Props {
    children: JSX.Element | Array<JSX.Element>;
}

export default function App({ children }: Props) {
    const isLoggingOut = useMeStore(state => state.isLoggingOut);

    const appInstalled = useInstallerStore(state => state.appInstalled);
    const setShowInstallBanner = useInstallerStore(state => state.setShow);
    const setDeferredPrompt = useInstallerStore(
        state => state.setDeferredPrompt
    );

    const installing = useBuildVersionStore(state => state.installing);
    const setInstalling = useBuildVersionStore(state => state.setInstalling);

    usePageTracking();
    useAppVersion();

    React.useEffect(() => {
        window.addEventListener('beforeinstallprompt', beforeinstallprompt);
        if (isInStandaloneMode()) {
            window.oncontextmenu = function (event) {
                event.preventDefault();
                event.stopPropagation();
                return false;
            };
        }
        return () => {
            window.removeEventListener(
                'beforeinstallprompt',
                beforeinstallprompt
            );
        };
    }, []);

    React.useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        if (installing) {
            if (!('serviceWorker' in navigator)) {
                ignoreUpdate();
            } else {
                timer = setTimeout(() => {
                    ignoreUpdate();
                }, 2000);
            }
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [installing]);

    React.useEffect(() => {
        if (appInstalled) {
            window.removeEventListener(
                'beforeinstallprompt',
                beforeinstallprompt
            );
        }
    }, [appInstalled]);

    const beforeinstallprompt = (e: Event) => {
        e.preventDefault();
        const declined = getCookie('install_dismissed');
        if (!declined) {
            setDeferredPrompt(e);
            setShowInstallBanner(true);
            ReactGA.event({
                category: 'PWA Install',
                action: 'Available',
            });
        }
        return false;
    };

    const ignoreUpdate = async () => {
        setInstalling(false);
        if ('serviceWorker' in navigator) {
            await serviceWorkerRegistration.unregister();
            const temp = await navigator.serviceWorker.getRegistration();
            if (temp) {
                await temp.unregister();
            }
        }
        window.location.reload();
    };

    const renderUpdateScreen = () => (
        <LoadingScreen
            text={'Installerar uppdatering'}
            subtitle={
                'Det kan ta några sekunder. Om det tar mer än 5 sekunder kan du klicka på knappen nedan för att ladda om sidan.'
            }
            cancelFunc={ignoreUpdate}
        />
    );
    const renderLogoutScreen = () => <LoadingScreen />;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
            <Theme>
                <>
                    {installing ? renderUpdateScreen() : null}
                    {isLoggingOut ? renderLogoutScreen() : children}
                    <NotificationManager />
                </>
            </Theme>
        </LocalizationProvider>
    );
}
