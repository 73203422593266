import React from 'react';
import File, { QueuedFile } from 'components/interface/files/File';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import Button from 'components/interface/Button/Button';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import useNotificationStore, {
    NotificationTypes,
} from 'store/useNotificationStore';
import ConfirmationMenu from 'components/interface/ConfirmationMenu/ConfirmationMenu';
import TextField from 'components/interface/textField/TextField';
import BlockRoute from 'components/interface/BlockRoute/BlockRoute';
import ReactGA from 'react-ga';

const useStyles = makeStyles()((theme, params, classes) => ({
    base: {
        width: '100%',
    },
    title: {
        userSelect: 'none',
    },
    description: {
        width: '100%',
        marginBottom: theme.spacing(2),
        userSelect: 'none',
    },
    filesWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(2),
    },
    addFilesWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(),
    },
    queue: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    queueActions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(4),
    },
    uploadButton: {
        marginLeft: theme.spacing(),
    },
}));

export default function FilesComponent({
    files,
    queue,
    onDownload,
    onTrash,
    onUpload,
    onEnqueue,
    onDequeue,
    onRenameQueuedFile,
    loading,
    uploading,
    error,
    onSignFile,
}) {
    const { classes, cx } = useStyles();
    const inputRef = React.useRef();
    const [renameDialogOpen, setRenameDialogOpen] = React.useState(false);
    const [renameFileData, setRenameFileData] = React.useState({
        id: 0,
        name: '',
    });

    const pushNotification = useNotificationStore(state => state.push);

    if (loading) {
        files = Array(4).fill({
            id: 0,
            name: '',
            uploader: {},
            category: {},
            size: 0,
        });
    }

    const handleClick = () => {
        const input = inputRef.current;
        if (input) input.click();
    };

    const handleChange = e => {
        if (e.target.files && e.target.files.length > 0) {
            onEnqueue(e.target.files);
        }
    };

    /** Rename */
    const handleRename = (id, name) => {
        setRenameFileData({ id, name });
        setRenameDialogOpen(true);
    };

    const handleRenameChange = value => {
        setRenameFileData({ ...renameFileData, name: value });
    };

    const confirmRename = () => {
        if (renameFileData.name.length === 0) {
            pushNotification(
                NotificationTypes.error,
                'Filnamnet får inte vara tom.',
                'file-rename-error',
                1
            );
        } else if (renameFileData.id === 0) {
            cancelRename();
        } else {
            onRenameQueuedFile(renameFileData.id, renameFileData.name);
            cancelRename();
        }
    };

    const cancelRename = () => {
        setRenameFileData({ id: 0, name: '' });
        setRenameDialogOpen(false);
    };

    const renameDialog = () => (
        <ConfirmationMenu
            open={renameDialogOpen}
            message={'Är du säker på att du vill redigera filnamnet?'}
            title={'Redigera filnamn'}
            cancelLabel={'Avbryt'}
            confirmLabel={'Ja, redigera filnamn'}
            cancelFunction={cancelRename}
            confirmFunction={confirmRename}
            onClose={cancelRename}
            fullWidth
            isMobile>
            <TextField
                value={renameFileData.name}
                placeholder='Filnamn...'
                onChange={handleRenameChange}
                type='text'
                fullWidth
            />
        </ConfirmationMenu>
    );

    const renderQueue = () => {
        return (
            <>
                <Typography
                    className={classes.title}
                    variant='h6'
                    color='textPrimary'>
                    <b>Ladda upp dokument</b>
                </Typography>
                <Typography
                    variant='body2'
                    color='textPrimary'
                    className={classes.description}>
                    Du kan välja att ladda upp ett eller flera dokument på samma
                    gång. När du är klar klickar du på “Ladda upp”.
                </Typography>

                <Typography variant='caption' color='textPrimary'>
                    <b>Uppladdningskö</b>
                </Typography>
                <div className={classes.queue}>
                    {queue
                        ? queue.map(file => (
                              <QueuedFile
                                  key={file.id}
                                  {...file}
                                  onRemove={onDequeue}
                                  onRename={handleRename}
                                  isMobile
                              />
                          ))
                        : undefined}
                </div>
                <div className={classes.queueActions}>
                    <Button variant='text' onClick={handleClick}>
                        Lägg till dokument
                    </Button>
                    <Button
                        className={classes.uploadButton}
                        color='primary'
                        variant='contained'
                        onClick={() => onUpload()}
                        endIcon={<UploadIcon />}
                        disableElevation
                        loading={uploading}>
                        Ladda upp
                    </Button>
                    <input
                        ref={inputRef}
                        type='file'
                        style={{ display: 'none' }}
                        onChange={handleChange}
                        multiple
                    />
                </div>
            </>
        );
    };

    const renderTitle = () => (
        <>
            <Typography
                className={classes.title}
                variant='h6'
                color='textPrimary'>
                <b>Dokument</b>
            </Typography>
            <Typography
                variant='body2'
                color='textPrimary'
                className={classes.description}>
                Här kan du ladda upp/ned dokument kopplat till ditt uppdrag.
            </Typography>
            <div className={classes.addFilesWrapper}>
                <Button
                    variant='outlined'
                    color='primary'
                    onClick={handleClick}>
                    Ladda upp dokument
                </Button>
                <input
                    ref={inputRef}
                    type='file'
                    style={{ display: 'none' }}
                    onChange={handleChange}
                    multiple
                />
            </div>
        </>
    );
    return (
        <div className={classes.base}>
            {queue.length > 0 ? renderQueue() : renderTitle()}
            <div className={classes.filesWrapper}>
                {files
                    ? files.map((file, i) => (
                          <File
                              key={file.id + '' + i}
                              {...file}
                              onDownload={file?.onDownload || onDownload}
                              onTrash={onTrash}
                              onSignFile={onSignFile}
                              isLoading={loading}
                              isMobile
                          />
                      ))
                    : null}
            </div>
            {renameDialog()}
            <BlockRoute
                shouldBlockNavigation={queue.length > 0}
                confirmFunc={() => {
                    ReactGA.event({
                        category: 'Files',
                        action: 'Save changes prompt',
                        label: 'stay',
                    });
                }}
                cancelFunc={() => {
                    ReactGA.event({
                        category: 'Files',
                        action: 'Save changes prompt',
                        label: 'leave',
                    });
                }}
                isMobile
            />
        </div>
    );
}
