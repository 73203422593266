import React from 'react';
import { shallow } from 'zustand/shallow';
import Api from 'api/Api';
import { getMajorVersionNumber } from 'utils/utils_legacy';
import useBuildVersionStore, {
    useForceInstallStore,
} from 'store/useBuildVersionStore';
import ReactGA from 'react-ga';

const useAppVersion = () => {
    const {
        version,
        installing,
        doInstall,
        updateAvailable,
        registration,
        waitingWorker,
        isWaiting,
        timestamp,
    } = useBuildVersionStore(
        state => ({
            version: state.version,
            installing: state.installing,
            doInstall: state.doInstall,
            updateAvailable: state.updateAvailable,
            registration: state.registration,
            waitingWorker: state.waitingWorker,
            isWaiting: state.isWaiting,
            timestamp: state.timestamp,
        }),
        shallow
    );
    const setVersion = useBuildVersionStore(state => state.setVersion);
    const setInstalling = useBuildVersionStore(state => state.setInstalling);
    const setDoInstall = useBuildVersionStore(state => state.setDoInstall);
    const setAvailable = useBuildVersionStore(state => state.setAvailable);
    const setRegistration = useBuildVersionStore(
        state => state.setRegistration
    );
    const setWaitingWorker = useBuildVersionStore(
        state => state.setWaitingWorker
    );
    const reset = useBuildVersionStore(state => state.reset);

    const forceInstall = useForceInstallStore(state => state.forceInstall);
    const setForceInstall = useForceInstallStore(
        state => state.setForceInstall
    );

    const [currentWorker, setCurrentWorker] = React.useState<
        ServiceWorkerRegistration | undefined
    >();
    const updateAvailableRef = React.useRef(false);
    const downloadedTimer = React.useRef<null | NodeJS.Timeout>(null);

    React.useEffect(() => {
        fetchVersion();
        const setCW = async () => {
            let cw = await navigator.serviceWorker.getRegistration();
            setCurrentWorker(cw);
            if (registration) {
                setRegistration(cw);
            }
        };
        setCW();
        return () => {
            if (downloadedTimer.current) {
                clearTimeout(downloadedTimer.current);
            }
        };
    }, []);

    React.useEffect(() => {
        if (currentWorker?.waiting && !waitingWorker) {
            setWaitingWorker(currentWorker.waiting);
        }
    }, [currentWorker?.waiting]);

    React.useEffect(() => {
        if (doInstall && updateAvailable) {
            setDoInstall(false);
            handleUpdate();
        }
        updateAvailableRef.current = updateAvailable;
    }, [doInstall, updateAvailable]);

    // Checks for new versions
    React.useEffect(() => {
        // If the version differs from the newest available version
        if (
            version &&
            process.env.REACT_APP_BUILD_VERSION &&
            process.env.REACT_APP_BUILD_VERSION !== version
        ) {
            // If service workers are supported by the browser
            if ('serviceWorker' in navigator) {
                if (
                    !!registration &&
                    typeof registration.update === 'function'
                ) {
                    // Downloads the new version
                    registration.update();
                    // If the new version is not downloaded within 4 seconds, show the banner
                    downloadedTimer.current = setTimeout(() => {
                        if (!updateAvailableRef.current) {
                            setAvailable(true);
                        }
                    }, 4000);
                }
            } else {
                // If the device does not support service workers
                if (
                    !forceInstall &&
                    getMajorVersionNumber(
                        process.env.REACT_APP_BUILD_VERSION
                    ) !== getMajorVersionNumber(version)
                ) {
                    setForceInstall(true);
                    handleUpdate();
                } else {
                    setAvailable(true);
                }
            }
        } else {
            setAvailable(false);
            setForceInstall(false);
            reset();
        }
    }, [version, registration, timestamp]);

    // Installs the new version, or shows the banner
    React.useEffect(() => {
        if ('serviceWorker' in navigator) {
            if (!!registration && !!waitingWorker) {
                // This checks for !forecInstall so we dont get a loop of refreshes.
                // If we havent initiated a force install yet, do it ONCE.
                if (
                    !forceInstall &&
                    getMajorVersionNumber(
                        process.env.REACT_APP_BUILD_VERSION || ''
                    ) !== getMajorVersionNumber(version || '')
                ) {
                    setForceInstall(true);
                    handleUpdate();
                } else {
                    setAvailable(true);
                }
            } else {
                setAvailable(false);
            }
        }
    }, [version, installing, waitingWorker, isWaiting]);

    const fetchVersion = async () => {
        try {
            const response = await Api.internalInfo.fetchBuildVersion();
            const { status, data } = response;
            if (status === 200) {
                setVersion(data.version);
            } else {
                throw new Error('');
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleUpdate = () => {
        ReactGA.event({
            category: 'PWA Update',
            action: 'Updated',
            label: 'hook',
        });
        if ('serviceWorker' in navigator) {
            if (!!registration && !!waitingWorker) {
                setInstalling(true);
                if (waitingWorker?.postMessage) {
                    waitingWorker.postMessage({ type: 'SKIP_WAITING' });
                }
            } else {
                window.location.reload();
            }
        } else {
            // If the device does not support service workers, simply reload so the new version is fetched
            window.location.reload();
        }
    };

    return null;
};

export default useAppVersion;
