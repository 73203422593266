import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { pink, grey } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/WarningOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const useStyles = makeStyles<
    {
        color: Color;
    },
    'withBorder' | 'fullWidth'
>()((theme, { color }, classes) => ({
    base: {
        borderRadius: theme.shape.borderRadius,
        display: 'inline-flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        userSelect: 'none',
        [`&.${classes.withBorder}`]: {
            border: '1px solid',
            borderColor: color.text,
        },
        [`&.${classes.fullWidth}`]: {
            width: '100%',
        },
    },
    background: { backgroundColor: color.background },
    text: { color: color.text, marginLeft: theme.spacing(1) },
    icon: { color: color.text },
    withBorder: {},
    fullWidth: {},
}));

export enum STATUS {
    NONE = 0,
    INFO = 1,
    WARNING = 2,
    ERROR = 3,
}

interface AlertAuxProps {
    classes?: string;
    color: Color;
    text: React.ReactNode;
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    withBorder?: boolean;
    fullWidth?: boolean;
}

type Color = {
    background: string;
    text: string;
};

const AlertAux = ({
    classes: classesProps,
    color,
    text,
    icon: Icon,
    withBorder = true,
    fullWidth,
}: AlertAuxProps) => {
    const { classes, cx } = useStyles({ color });

    return (
        <div
            className={cx(classes.base, classes.background, classesProps, {
                [classes.withBorder]: withBorder,
                [classes.fullWidth]: fullWidth,
            })}>
            <Icon className={classes.icon} fontSize='small' />
            <Typography variant='body2' className={classes.text}>
                {text}
            </Typography>
        </div>
    );
};

interface AlertProps extends Omit<AlertAuxProps, 'color' | 'icon'> {
    status: STATUS;
}

export default function Alert({ status, ...rest }: AlertProps) {
    const theme = useTheme();

    const getColor = (): Color => {
        switch (status) {
            case 1:
                return {
                    background: theme.palette.primary[50],
                    text: theme.palette.primary[1000],
                };
            case 2:
                return {
                    background: theme.palette.yellow[100],
                    text: theme.palette.yellow[1000],
                };
            case 3:
                return {
                    background: pink[50],
                    text: pink[900],
                };
            default:
                return {
                    background: grey[200],
                    text: grey[900],
                };
        }
    };

    const getIcon = () => {
        switch (status) {
            case 1:
                return InfoIcon;
            case 2:
                return WarningIcon;
            case 3:
                return ErrorIcon;
            default:
                return InfoIcon;
        }
    };

    return <AlertAux color={getColor()} icon={getIcon()} {...rest} />;
}
