import React from 'react';
import Api, { AppType, IS_DEV } from 'api/Api';
import useNotificationStore, {
    NotificationTypes,
} from 'store/useNotificationStore';
import { setCookie, getCookie, eraseCookie } from 'utils/cookies';
import useMeStore, { SessionData } from 'store/useMeStore';
import { useHistory } from 'react-router-dom';
import { resetAllStores } from 'store/create';
import { APP_TYPE } from 'utils/env';

// Minimum amount of time that the login should take, in ms.
// This ensures that the loading animation is smooth
const LOGIN_MIN_TIME = 600;

const getSameSiteCookie = () => {
    let cookie = IS_DEV ? 'dev-' : '';
    // @ts-ignore
    if (AppType === APP_TYPE.TOOLS) {
        return cookie + 'ToolsxJWTv2';
    } else if (AppType === APP_TYPE.KONSULT) {
        return cookie + 'xJWT';
    }
};

const getOppositeSiteCookie = () => {
    let cookie = IS_DEV ? 'dev-' : '';
    // @ts-ignore
    if (AppType === APP_TYPE.TOOLS) {
        return cookie + 'xJWT';
    } else if (AppType === APP_TYPE.KONSULT) {
        return cookie + 'ToolsxJWTv2';
    }
};

const getOppositeSiteURL = () => {
    let url = 'https://' + (IS_DEV ? 'dev-' : '');
    // @ts-ignore
    if (AppType === APP_TYPE.TOOLS) {
        return url + 'konsult.vbsverige.se';
    } else if (AppType === APP_TYPE.KONSULT) {
        return url + 'tools.vbsverige.se';
    }
    return url;
};

export default function useLogin(redirectedFrom: string | null) {
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const setUserData = useMeStore(state => state.setUserData);
    const history = useHistory();

    const pushNotification = useNotificationStore(state => state.push);

    React.useEffect(() => {
        const cookie_name = getSameSiteCookie();
        const jwt = getCookie(cookie_name);
        if (jwt) {
            eraseCookie(cookie_name, 'vbsverige.se');
            loginWithSessionToken(jwt);
        } else {
            setLoading(false);
        }
    }, []);

    const login = async (username: string, password: string) => {
        const start = new Date();
        setLoading(true);
        setError(false);

        try {
            const response = await Api.user.loginToUser(username, password);
            const { status, data } = response;
            if (status === 200) {
                const { user, puppeteer, token } = data;
                await setLoginData(start, { user, puppeteer, token });
            } else {
                throw new Error('');
            }
        } catch (err) {
            console.log(err);
            setError(true);
            pushNotification(
                NotificationTypes.error,
                'Du har angivit fel användarnamn eller lösenord.',
                'login-fail',
                1
            );
        }
        setLoading(false);
    };

    const loginWithSessionToken = async (jwt: string) => {
        setLoading(true);
        const start = new Date();
        try {
            const response = await Api.user.getSessionData(jwt);
            const { status, data } = response;
            if (status === 200) {
                const { user, puppeteer, token } = data;
                await setLoginData(start, { user, puppeteer, token });
            } else {
                throw new Error('Failed logging in');
            }
        } catch (e) {
            pushNotification(
                NotificationTypes.error,
                'Det gick inte att logga in, försök igen.',
                'login-fail',
                1
            );
        }
        setLoading(false);
    };

    // Internal function that should not be exposed
    const _setLoginData = async (start: Date, data: SessionData) => {
        resetAllStores();

        const end = new Date();
        var diffMS = LOGIN_MIN_TIME - (end.getTime() - start.getTime());
        await new Promise<void>(resolve =>
            setTimeout(
                () => {
                    resolve();
                },
                diffMS > 0 ? diffMS : 1
            )
        );
        setUserData(data);
        const redirectPath =
            redirectedFrom === '/logga-ut' ? null : redirectedFrom;
        history.replace(redirectPath || '/');
        return;
    };

    // IMPORTANT: this login logic with redirect is specific for tools and konsult site. so this function CANNOT be copied over.
    const setLoginData = async (
        start: Date,
        { user, puppeteer, token }: SessionData
    ) => {
        setLoading(true);
        if (user.ulevel !== 3) {
            if (user.ulevel === 1 || user.ulevel === 2) {
                const cookie_name = getOppositeSiteCookie();
                setCookie(cookie_name, token, 1, 'vbsverige.se');
                window.location.replace(getOppositeSiteURL());
            }
        } else {
            await _setLoginData(start, { user, puppeteer, token });
        }
        setLoading(false);
    };

    return { login, loading, error, setLoginData };
}
