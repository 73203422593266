import React from 'react';
import {
    ThemeProvider,
    createTheme,
    PaletteColorOptions,
} from '@mui/material/styles';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { red, grey } from '@mui/material/colors';
import { primary, yellow } from 'utils/color';

export const muiCache = createCache({
    key: 'mui',
    prepend: true,
});

declare module '@mui/material/styles' {
    interface TypeBackground {
        backgroundColor: string;
        backgroundColorDark: string;
    }
    interface TypeText {
        hint: string;
    }
    interface PaletteOptions {
        primaryDark: PaletteColorOptions;
        primaryLight: PaletteColorOptions;
        yellow: PaletteColorOptions;
        white: PaletteColorOptions;
        bluegreen: PaletteColorOptions;
        gray: PaletteColorOptions;
    }
    interface Palette {
        primaryDark: PaletteColor;
        primaryLight: PaletteColor;
        yellow: PaletteColor;
        white: PaletteColor;
        bluegreen: PaletteColor;
        gray: PaletteColor;
    }
    interface PaletteColor {
        50: string;
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
        600: string;
        700: string;
        800: string;
        900: string;
        1000: string;
        A100: string;
        A200: string;
        A400: string;
        A700: string;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        primaryDark: true;
        primaryLight: true;
        gray: true;
        yellow: true;
        bluegreen: true;
    }
}

const theme = createTheme({
    palette: {
        primary: {
            ...primary,
        },
        primaryDark: {
            light: '#819fa1',
            main: '#023f42',
            dark: '#01292b',
            contrastText: '#fff',
            50: '#e1e8e8',
            100: '#b3c5c6',
            200: '#819fa1',
            300: '#4e797b',
            400: '#285c5e',
            500: '#023f42',
            600: '#02393c',
            700: '#013133',
            800: '#01292b',
            900: '#011b1d',
            A100: '#5becff',
            A200: '#28e6ff',
            A400: '#00d7f4',
            A700: '#00c1da',
        },
        primaryLight: {
            light: '#abf0f5',
            main: '#56e0eb',
            dark: '#3cd3e2',
            contrastText: '#424242',
            50: '#ebfbfd',
            100: '#ccf6f9',
            200: '#abf0f5',
            300: '#89e9f1',
            400: '#6fe5ee',
            500: '#56e0eb',
            600: '#4fdce9',
            700: '#45d8e5',
            800: '#3cd3e2',
            900: '#2bcbdd',
            A100: '#ffffff',
            A200: '#eafdff',
            A400: '#b7f7ff',
            A700: '#9df5ff',
        },
        secondary: {
            light: '#FFF5F6',
            main: '#FFCBD0',
            dark: '#F6ADB4',
            contrastText: '#6B4045',
            100: '#FFF5F6',
            200: '#FFEBED',
            300: '#FFE5E8',
            400: '#FFDBDF',
            500: '#FFCBD0',
            600: '#F6ADB4',
            700: '#D27982',
            800: '#924952',
            900: '#6B4045',
            A100: '#ffffff',
            A200: '#ffffff',
            A400: '#ffffff',
            A700: '#ffffff',
        },
        yellow: {
            ...yellow,
        },
        gray: {
            // note that we use gray instead of grey here, this is because we can't override the default grey.
            ...grey,
            light: grey[100],
            main: grey[900],
            dark: grey[900],
            contrastText: grey[300],
        },
        error: red,
        white: {
            light: '#ffffff',
            main: '#ffffff',
            dark: '#ffffff',
            contrastText: '#424242',
        },
        bluegreen: {
            light: '#d2f0ee',
            main: '#008683',
            dark: '#005452',
            contrastText: '#ffffff',
        },
        background: {
            backgroundColor: 'hsl(187, 15%, 96%)',
            backgroundColorDark: 'hsl(187, 15%, 92%)',
        },
        text: {
            disabled: 'rgba(0, 0, 0, 0.68)',
            hint: 'rgba(0, 0, 0, 0.38)',
        },
    },
    shape: {
        borderRadius: 8,
    },
});

//.background.back => bg.

interface Props {
    children: JSX.Element | Array<JSX.Element>;
}

export default function Theme({ children }: Props): JSX.Element {
    return (
        <CacheProvider value={muiCache}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </CacheProvider>
    );
}
