import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Button from 'components/interface/Button/Button';
import { Dialog, Typography } from '@mui/material';
import { isMobile, isTablet } from 'react-device-detect';
import { useBankID } from './hooks/useBankID';
import { WelcomeText } from 'components/landingPage/Wrapper';
import { Loading } from 'components/interface/Loading';
import { DigitalIdPayload, DigitalIdRequestEndpoint, STATUS } from './typings';

const useStyles = makeStyles()((theme, _params, _classes) => ({
    base: {
        maxWidth: '350px',
        width: '100%',
    },
    loginButton: {
        width: '100%',
        height: '40px',
        marginTop: theme.spacing(2),
    },
    bankID: {
        'height': '26px',
        'marginRight': '13px', // has to be halv of size according to BankID spec https://www.bankid.com/utvecklare/varumaerkesregler/varumaerke-logotypen
        '& svg': {
            height: '26px',
        },
    },
    status: {
        color: theme.palette.primaryDark.main,
        paddingBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    statusText: {
        alignSelf: 'center',
    },
    loadingSpinner: {
        marginBottom: theme.spacing(4),
        alignSelf: 'center',
    },
    qrCode: {
        marginBottom: theme.spacing(4),
        borderRadius: '16px',
        transition: 'all 250ms ease-in-out',
        height: '0px',
        alignSelf: 'center',
    },
    qrCodeFull: {
        height: '256px',
    },
    desktop: {},
}));

interface BankIDProps<T> {
    onGoBack: () => void;
    onSuccess: (respone: T) => void;
    type: TYPE;
    endPoint: DigitalIdRequestEndpoint;
    title: string;
    subtitle?: string;
    closeText?: string;
    payload?: DigitalIdPayload;
}

export enum BANKID_METHOD {
    MOBILE_SAME_DEVICE = 1,
    MOBILE_OTHER_DEVICE = 2, // QR code
    DESKTOP_SAME_DEVICE = 3,
}

export enum TYPE {
    AUTH = 'AUTH',
    SIGN = 'SIGN',
}

export default function BankID<T>({
    onGoBack,
    onSuccess,
    type,
    endPoint,
    title,
    subtitle,
    closeText,
    payload,
}: BankIDProps<T>) {
    const { classes, cx } = useStyles();
    const [selectedMethod, setSelectedMethod] = React.useState<
        BANKID_METHOD | undefined
    >();

    const handleSuccess = (payload: any) => {
        onSuccess(payload as T);
    };

    const { start, cancel, status, message, qrCode, autoStartToken } =
        useBankID({ onSuccess: handleSuccess, endPoint, payload });

    const displayMobile = isMobile || isTablet;

    React.useEffect(() => {
        if (
            status === STATUS.STARTED &&
            autoStartToken &&
            selectedMethod !== BANKID_METHOD.MOBILE_OTHER_DEVICE
        ) {
            let redirect = encodeURIComponent(window.location.href);
            // bankid recommends the redirect to be null for every platform except for iOS
            /*if (!isIOS) {
                redirect = 'null';
            }*/
            redirect = 'null';
            window.open(
                `bankid:///?autostarttoken=${autoStartToken}&redirect=${redirect}`,
                '_self'
            );
        }
    }, [status, autoStartToken]);

    const handleSetMethod = (v: BANKID_METHOD) => {
        setSelectedMethod(v);
        start(v);
    };

    const handleCancelAuthentication = () => {
        cancel();
        setSelectedMethod(undefined);
    };

    const handleGoBack = () => {
        handleCancelAuthentication();
        onGoBack();
    };

    const renderBankIDMethods = () => {
        const renderMobile = () => (
            <>
                <Button
                    color='primaryDark'
                    variant='contained'
                    onClick={() =>
                        handleSetMethod(BANKID_METHOD.MOBILE_SAME_DEVICE)
                    }
                    classes={{ root: classes.loginButton }}
                    disableElevation>
                    <Typography variant='body1' color='inherit'>
                        <b>Öppna Mobilt BankID</b>
                    </Typography>
                </Button>
                <Button
                    color='primaryDark'
                    variant='outlined'
                    onClick={() =>
                        handleSetMethod(BANKID_METHOD.MOBILE_OTHER_DEVICE)
                    }
                    classes={{ root: classes.loginButton }}>
                    <Typography variant='body1' color='inherit'>
                        <b>Mobilt BankID på annan enhet</b>
                    </Typography>
                </Button>
            </>
        );

        const renderDesktop = () => (
            <>
                <Button
                    color='primaryDark'
                    variant='contained'
                    onClick={() =>
                        handleSetMethod(BANKID_METHOD.MOBILE_OTHER_DEVICE)
                    }
                    classes={{ root: classes.loginButton }}
                    disableElevation>
                    <Typography variant='body1' color='inherit'>
                        <b>Mobilt BankID på annan enhet</b>
                    </Typography>
                </Button>
                <Button
                    color='primaryDark'
                    variant='outlined'
                    onClick={() =>
                        handleSetMethod(BANKID_METHOD.DESKTOP_SAME_DEVICE)
                    }
                    classes={{ root: classes.loginButton }}>
                    <Typography variant='body1' color='inherit'>
                        <b>Öppna BankID</b>
                    </Typography>
                </Button>
            </>
        );

        return (
            <>
                {displayMobile ? renderMobile() : renderDesktop()}
                <Button
                    color='primaryDark'
                    variant='text'
                    onClick={handleGoBack}
                    classes={{ root: classes.loginButton }}>
                    <Typography variant='body1' color='inherit'>
                        <b>{closeText || 'Tillbaka'}</b>
                    </Typography>
                </Button>
            </>
        );
    };

    const renderAuthenticating = () => {
        const renderQRCode = () => (
            <>
                <WelcomeText
                    title={
                        type === TYPE.AUTH
                            ? 'Logga in med BankID'
                            : 'Skriv under med BankID'
                    }
                    subtitle='Öppna BankID-appen och scanna QR-koden.'
                />
                <img
                    src={'data:image/png;base64,' + qrCode}
                    className={cx(classes.qrCode, {
                        [classes.qrCodeFull]: !!qrCode,
                    })}
                    draggable='false'
                />
            </>
        );

        const renderNormal = () => (
            <>
                <WelcomeText
                    title={
                        type === TYPE.AUTH
                            ? 'Logga in med BankID'
                            : 'Skriv under med BankID'
                    }
                    subtitle='Öppna BankID-appen.'
                />
                {status === STATUS.PENDING ? (
                    <Loading className={classes.loadingSpinner} />
                ) : null}
            </>
        );

        return (
            <>
                <div className={classes.status}>
                    {selectedMethod === BANKID_METHOD.MOBILE_OTHER_DEVICE
                        ? renderQRCode()
                        : renderNormal()}
                    <Typography
                        className={classes.statusText}
                        variant='body1'
                        color='inherit'>
                        <b>{message}</b>
                    </Typography>
                </div>

                <Button
                    key='cancel-button'
                    color='primaryDark'
                    variant={
                        status === STATUS.FAILED ? 'contained' : 'outlined'
                    }
                    onClick={handleCancelAuthentication}
                    classes={{ root: classes.loginButton }}>
                    <Typography variant='body1' color='inherit'>
                        <b>
                            {status === STATUS.FAILED
                                ? 'Försök igen'
                                : 'Avbryt'}
                        </b>
                    </Typography>
                </Button>
            </>
        );
    };

    const renderStep = () => {
        if (selectedMethod) {
            return renderAuthenticating();
        }
        return (
            <>
                {renderTitle()}
                {renderBankIDMethods()}
            </>
        );
    };

    const renderTitle = () => <WelcomeText title={title} subtitle={subtitle} />;

    return <div className={classes.base}>{renderStep()}</div>;
}

interface BankIDDialogProps<T> extends BankIDProps<T> {
    open: boolean;
    isMobile?: boolean;
}

const useStylesDialog = makeStyles<void, 'success' | 'mobile'>()(
    (theme, _params, classes) => ({
        base: {
            backgroundImage: 'url(/images/pattern.png)',
            padding: theme.spacing(4),
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'row',
            [`&.${classes.success}`]: {
                backgroundImage: 'url(/images/pattern_animation.gif)',
            },
            [`&.${classes.mobile}`]: {
                paddingTop: 'calc(32px + env(safe-area-inset-top, 0px))',
                paddingBottom: 'calc(32px + env(safe-area-inset-bottom, 0px))',
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                overflow: 'scroll',
            },
        },
        success: {},
        mobile: {},
        preloadLoadingAnimation: {
            backgroundImage: 'url(/images/pattern_animation.gif)',
            display: 'hidden',
        },
        wrapper: {
            opacity: 1,
            transition: 'opacity 0.5s ease-in-out',
            [`&.${classes.success}`]: {
                opacity: 0,
            },
        },
        successText: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0,
            transition: 'opacity 0.5s ease-in-out',
            color: theme.palette.primaryDark.main,
            pointerEvents: 'none',
            [`&.${classes.success}`]: {
                opacity: 1,
            },
        },
    })
);

export function BankIDDialog<T>({
    open,
    onSuccess,
    isMobile,
    ...rest
}: BankIDDialogProps<T>) {
    const { classes, cx } = useStylesDialog();
    const [success, setSuccess] = React.useState(false);

    React.useEffect(() => {
        if (!open && success) {
            setSuccess(false);
        }
    }, [open]);

    const handleSuccess = (v: T) => {
        setSuccess(true);
        setTimeout(() => {
            onSuccess(v);
        }, 1500);
    };

    return (
        <Dialog
            open={open}
            classes={{
                paper: cx(classes.base, {
                    [classes.success]: success,
                    [classes.mobile]: isMobile,
                }),
            }}
            fullScreen={isMobile}>
            <div className={classes.preloadLoadingAnimation} />
            <div
                className={cx(classes.wrapper, { [classes.success]: success })}>
                <BankID<T>
                    closeText='Stäng'
                    {...rest}
                    onSuccess={handleSuccess}
                />
            </div>
            <div
                className={cx(classes.successText, {
                    [classes.success]: success,
                })}>
                <Typography variant='h6' color='inherit'>
                    Klart!
                </Typography>
            </div>
        </Dialog>
    );
}
