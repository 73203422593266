import React from 'react';
import {
    Timesheet,
    TIMESHEET_SETTING,
    TimesheetDay,
    TravelsheetKeyType,
    calculateTravelsheetColumnSum,
    calculateSumPerColumn,
    calculateTotalReportedTime,
    Summary,
    addSickHours,
    OtherTimeReportedHoursForWeek,
} from './typings';
import DesktopView from './desktop/View';
import MobileView from './mobile/View';
import { debounce } from 'lodash';
import BlockRoute from '../BlockRoute/BlockRoute';

export interface TimesheetProps {
    timesheet: Timesheet | null;
    setTimesheet: React.Dispatch<React.SetStateAction<Timesheet | null>>;
    timesheetSetting: TIMESHEET_SETTING;
    canSubmit: boolean;
    allowTravelsheet: boolean;
    loading: boolean;
    submitting: boolean;
    success: boolean;
    error: boolean;
    isMobile: boolean;
    enableSickHours: boolean;
    otherTimeReportedHoursForWeek: OtherTimeReportedHoursForWeek;
    onEnableSickHours: React.Dispatch<React.SetStateAction<boolean>>;
    useSplitTextfields: boolean;
    setUseSplitTextfields?: (value: boolean) => void;
}

const View = ({
    timesheet,
    setTimesheet,
    timesheetSetting,
    canSubmit,
    allowTravelsheet,
    loading,
    success,
    error,
    isMobile,
    enableSickHours,
    otherTimeReportedHoursForWeek,
    onEnableSickHours,
    useSplitTextfields,
    setUseSplitTextfields,
}: TimesheetProps): JSX.Element => {
    const [_initialTimesheet, setTimesheetInitial] =
        React.useState<Timesheet | null>(null);
    const [summary, setSummary] = React.useState<Summary>([]);

    const [doUpdateTimesheetSum, setDoUpdateTimesheetSum] =
        React.useState<boolean>(false);
    const [doUpdateTravelsheetSum, setDoUpdateTravelsheetSum] =
        React.useState<boolean>(false);

    const handleSetDoUpdateTimesheetSum = React.useRef(
        debounce(() => setDoUpdateTimesheetSum(true), 200)
    );
    const handleSetDoUpdateTravelsheetSum = React.useRef(
        debounce(() => setDoUpdateTravelsheetSum(true), 200)
    );

    React.useEffect(() => {
        if (!loading && timesheet) {
            // this will do a deep copy
            setTimesheetInitial(JSON.parse(JSON.stringify(timesheet)));
            onEnableSickHours(timesheet.hasSickHours);
            handleSetDoUpdateTimesheetSum.current();
            handleSetDoUpdateTravelsheetSum.current();
        }
    }, [loading]);

    React.useEffect(() => {
        if (doUpdateTimesheetSum) {
            setDoUpdateTimesheetSum(false);
            updateTimesheetSum();
        }
    }, [doUpdateTimesheetSum]);

    React.useEffect(() => {
        if (doUpdateTravelsheetSum) {
            setDoUpdateTravelsheetSum(false);
            updateTravelsheetSum();
        }
    }, [doUpdateTravelsheetSum]);

    const handleUpdateTimesheetDay = (
        updateFunc: (day: TimesheetDay) => TimesheetDay,
        index: number
    ) => {
        setTimesheet(prev => {
            if (!prev) {
                return prev;
            }
            const t = { ...prev };
            t.days[index] = updateFunc(t.days[index]);
            return t;
        });
        handleSetDoUpdateTimesheetSum.current();
    };

    const handleTravelsheetFieldChange =
        (key: TravelsheetKeyType, index: number) => (value: string) => {
            setTimesheet(prev => {
                if (!prev || !prev.travelsheet) {
                    return prev;
                }
                const t = { ...prev, travelsheet: { ...prev.travelsheet } };
                let values = t?.travelsheet[key]?.slice() || [];
                if (values.length < 1) {
                    // Hardcoded like the column count for the travelsheet
                    values = Array(6).fill('');
                }
                values[index] = value;
                t.travelsheet[key] = values;
                return t;
            });
            handleSetDoUpdateTravelsheetSum.current();
        };

    const updateTimesheetSum = () => {
        if (!timesheet) {
            return;
        }
        const summary = calculateSumPerColumn(timesheet);
        const totalHours = calculateTotalReportedTime(
            timesheet,
            timesheetSetting
        );

        setSummary(summary);
        setTimesheet(prev => {
            if (!prev) {
                return prev;
            }
            return {
                ...prev,
                totalHours: totalHours,
            };
        });
    };

    const updateTravelsheetSum = () => {
        if (!timesheet?.travelsheet) {
            return;
        }
        const totalMileage = calculateTravelsheetColumnSum(
            timesheet.travelsheet,
            4
        );
        const totalPayment = calculateTravelsheetColumnSum(
            timesheet.travelsheet,
            5
        );

        if (
            totalMileage === timesheet.travelsheet?.totalMileage &&
            totalPayment === timesheet.travelsheet?.totalPayment
        ) {
            return;
        }
        setTimesheet(prev => {
            if (!prev || !prev.travelsheet) {
                return prev;
            }
            return {
                ...prev,
                travelsheet: {
                    ...prev.travelsheet,
                    totalMileage: totalMileage,
                    totalPayment: totalPayment,
                },
            };
        });
    };

    const handleNotesFieldChange = (value: string) => {
        setTimesheet(
            prev =>
                ({
                    ...prev,
                    notes: value,
                }) as Timesheet
        );
    };

    const handleEnableSickHours = (value: boolean) => {
        onEnableSickHours(value);
        if (value) {
            setTimesheet(prev => {
                if (!prev) {
                    return prev;
                }
                return addSickHours(prev, timesheetSetting);
            });
        }
    };

    return (
        <>
            {!isMobile ? (
                <DesktopView
                    timesheet={timesheet}
                    summary={summary}
                    timesheetSetting={timesheetSetting}
                    canSubmit={canSubmit}
                    useSplitTextfields={useSplitTextfields}
                    loading={loading}
                    success={success}
                    error={error}
                    allowTravelsheet={allowTravelsheet}
                    enableSickHours={enableSickHours}
                    otherTimeReportedHoursForWeek={
                        otherTimeReportedHoursForWeek
                    }
                    onEnableSickHours={handleEnableSickHours}
                    onUpdateTimesheetDay={handleUpdateTimesheetDay}
                    onTravelsheetFieldChange={handleTravelsheetFieldChange}
                    onNotesFieldChange={handleNotesFieldChange}
                    setUseSplitTextfields={setUseSplitTextfields}
                />
            ) : (
                <MobileView
                    timesheet={timesheet}
                    timesheetSetting={timesheetSetting}
                    canSubmit={canSubmit}
                    useSplitTextfields={useSplitTextfields}
                    loading={loading}
                    success={success}
                    error={error}
                    allowTravelsheet={allowTravelsheet}
                    enableSickHours={enableSickHours}
                    onEnableSickHours={handleEnableSickHours}
                    onUpdateTimesheetDay={handleUpdateTimesheetDay}
                    onTravelsheetFieldChange={handleTravelsheetFieldChange}
                    onNotesFieldChange={handleNotesFieldChange}
                />
            )}
            <BlockRoute
                shouldBlockNavigation={
                    _initialTimesheet?.totalHours !== timesheet?.totalHours &&
                    success === false &&
                    canSubmit
                }
                isMobile={isMobile}
            />
        </>
    );
};

export default View;
