import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Button from 'components/interface/Button/Button';
import { Typography } from '@mui/material';
import { LOGIN_METHOD } from './store/useLoginStore';
import { SessionData } from 'store/useMeStore';
import { ReactComponent as BankIDLogo } from 'assets/BankID_logo_no_padding_white.svg';
import BankID, { TYPE } from 'components/bankID/BankID';
import { WelcomeText } from '../Wrapper';
import useLogin from './hooks/useLogin';
import api from 'api/Api';

const useStyles = makeStyles()((theme, _params, _classes) => ({
    loginButtonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'space-between',
        paddingTop: theme.spacing(1),
    },
    loginButton: {
        width: '100%',
        height: '40px',
        marginTop: theme.spacing(2),
    },
    bankID: {
        'height': '26px',
        'marginRight': '13px', // has to be halv of size according to BankID spec https://www.bankid.com/utvecklare/varumaerkesregler/varumaerke-logotypen
        '& svg': {
            height: '26px',
        },
    },
    status: {
        color: theme.palette.primaryDark.main,
        paddingBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingSpinner: {
        marginBottom: theme.spacing(4),
    },
    qrCode: {
        marginBottom: theme.spacing(4),
        borderRadius: '16px',
    },
    desktop: {},
}));

interface Props {
    setLoading: (loading: boolean) => void;
    setMethod: (newLoginMethod: LOGIN_METHOD) => void;
    redirectedFrom: string | null;
    loginMethodAlreadyChosen?: boolean;
}

enum STEP {
    CHOOSE_LOGIN_METHOD = 1, // Choose if you want to login with mail or bankid
    CHOOSE_BANKID_METHOD = 2, // Choose how you want to authenticate, mobile etc
}

export const LoginWithBankID = ({
    setLoading,
    setMethod,
    redirectedFrom,
    loginMethodAlreadyChosen,
}: Props) => {
    const { classes } = useStyles();
    const [currentStep, setCurrentStep] = React.useState(
        STEP.CHOOSE_LOGIN_METHOD
    );
    const { setLoginData, loading } = useLogin(redirectedFrom);

    React.useEffect(() => {
        setLoading(loading);
    }, [loading]);

    React.useEffect(() => {
        if (loginMethodAlreadyChosen) {
            setCurrentStep(STEP.CHOOSE_BANKID_METHOD);
        }
    }, []);

    const handleGoBack = () => {
        setMethod(LOGIN_METHOD.NORMAL);
        setCurrentStep(STEP.CHOOSE_LOGIN_METHOD);
    };

    const renderLoginMethods = () => {
        return (
            <>
                <WelcomeText
                    title='Välkommen tillbaka!'
                    subtitle='Välj identifieringsmetod för att fortsätta.'
                />
                <div className={classes.loginButtonWrapper}>
                    <Button
                        color='primaryDark'
                        variant='contained'
                        onClick={() =>
                            setCurrentStep(STEP.CHOOSE_BANKID_METHOD)
                        }
                        classes={{ root: classes.loginButton }}
                        disableElevation>
                        <BankIDLogo className={classes.bankID} />
                        <Typography variant='body1' color='inherit'>
                            <b>Logga in med BankID</b>
                        </Typography>
                    </Button>
                    <Button
                        color='primaryDark'
                        variant='outlined'
                        onClick={() => setMethod(LOGIN_METHOD.NORMAL)}
                        classes={{ root: classes.loginButton }}>
                        <Typography variant='body1' color='inherit'>
                            <b>Logga in med e-post</b>
                        </Typography>
                    </Button>
                </div>
            </>
        );
    };

    return (
        <div>
            {currentStep === STEP.CHOOSE_LOGIN_METHOD
                ? renderLoginMethods()
                : null}
            {currentStep === STEP.CHOOSE_BANKID_METHOD ? (
                <BankID<SessionData>
                    onGoBack={handleGoBack}
                    onSuccess={v => setLoginData(new Date(), v)}
                    type={TYPE.AUTH}
                    endPoint={api.user.loginDigitalID}
                    title='Välkommen tillbaka!'
                    subtitle='Välj identifieringsmetod för att fortsätta.'
                />
            ) : null}
        </div>
    );
};
