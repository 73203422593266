import React from 'react';
import { BANKID_METHOD } from '../BankID';
import {
    BuildDigitalIdResponse,
    DIGITAL_ID_METHOD,
    DigitalIdPayload,
    DigitalIdRequestEndpoint,
    STATUS,
} from '../typings';
import api from 'api/Api';
import useMeStore from 'store/useMeStore';

interface useBankIDProps {
    onSuccess: (v: any) => void;
    endPoint: DigitalIdRequestEndpoint;
    payload?: DigitalIdPayload;
}

const pollRate = 1200; // ms

export function useBankID({ payload, onSuccess, endPoint }: useBankIDProps) {
    const [status, setStatus] = React.useState<STATUS>(STATUS.NOT_STARTED);
    const [message, setMessage] = React.useState('');
    const [qrCode, setQRCode] = React.useState('');
    const [transactionId, setTransactionId] = React.useState('');
    const [autoStartToken, setAutoStartToken] = React.useState('');

    const interval = React.useRef<NodeJS.Timer | null>(null);
    const token = useMeStore(state => state.token);

    // This will also stop the polling timer if transaction id is unset
    React.useEffect(() => {
        queuePolling();
    }, [transactionId]);

    const stopPolling = () => {
        if (interval.current) {
            clearTimeout(interval.current);
            interval.current = null;
        }
    };

    const queuePolling = () => {
        stopPolling();
        if (!interval.current && transactionId) {
            interval.current = setTimeout(collect, pollRate);
        }
    };

    const resetState = () => {
        setStatus(STATUS.NOT_STARTED);
        setMessage('');
        setQRCode('');
        setTransactionId('');
        setAutoStartToken('');
        stopPolling();
        if (interval.current) {
            clearInterval(interval.current);
        }
    };

    const start = async (_method: BANKID_METHOD) => {
        resetState();

        try {
            const response = await endPoint(
                DIGITAL_ID_METHOD.BANKID,
                token,
                undefined,
                payload
            );
            const { status, data } = response;
            if (status === 200) {
                const statusResponse = BuildDigitalIdResponse(data);
                if (statusResponse) {
                    setTransactionId(data.transactionId);
                    setStatus(data.status);
                    setMessage(data.message || '');
                    setQRCode(data.qrCode || '');
                    if (data.data?.autoStartToken) {
                        setAutoStartToken(data.data?.autoStartToken);
                    }
                } else {
                    throw new Error('');
                }
            } else {
                throw new Error('');
            }
        } catch (err) {
            console.log(err);
            setMessage('Okänt fel. Försök igen');
            setStatus(STATUS.FAILED);
        }
    };

    const collect = async () => {
        stopPolling();
        if (!transactionId) {
            return;
        }
        try {
            const response = await endPoint(
                DIGITAL_ID_METHOD.BANKID,
                token,
                transactionId,
                payload
            );
            const { status, data } = response;
            if (status === 200) {
                const statusResponse = BuildDigitalIdResponse(data);
                if (statusResponse) {
                    setStatus(data.status);
                    setMessage(data.message || '');
                    // This is so the qrCode doesn't disappear during the animation
                    if (data.qrCode) {
                        setQRCode(data.qrCode);
                    }
                    if (data.status === STATUS.COMPLETE) {
                        onSuccess(data.data);
                        setTransactionId('');
                    } else if (data.status === STATUS.FAILED) {
                        setTransactionId('');
                    }
                } else {
                    // if status code is 200 but no status is present in the response means that it should be complete
                    setStatus(STATUS.COMPLETE);
                    onSuccess(data);
                    setTransactionId('');
                }
            } else {
                throw new Error('');
            }
        } catch (error: any) {
            console.log(error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                if (error.response.data?.message) {
                    setMessage(error.response.data.message);
                } else {
                    setMessage('Okänt fel. Försök igen');
                }
                setStatus(STATUS.FAILED);
                setTransactionId('');
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log('request error', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        }
        queuePolling();
    };

    const cancel = async () => {
        stopPolling();
        if (!transactionId) {
            resetState();
            return;
        }
        try {
            await api.digitalId.cancel(DIGITAL_ID_METHOD.BANKID, transactionId);
        } catch (err) {
            console.log(err);
        }
        resetState();
    };

    return {
        start,
        cancel,
        status,
        message,
        qrCode,
        autoStartToken,
    };
}
