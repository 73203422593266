import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Logo from 'components/interface/Logo/Logo';
import { Typography } from '@mui/material';

const useStyles = makeStyles<void, 'desktop' | 'mobile' | 'loading'>()(
    (theme, _params, classes) => ({
        base: {
            height: '100%',
            width: '100%',
            display: 'flex',
        },
        content: {
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundImage: 'url(/images/pattern.png)',
            [`&.${classes.mobile}`]: {
                paddingTop: 'calc(32px + env(safe-area-inset-top, 0px))',
                paddingBottom: 'calc(32px + env(safe-area-inset-bottom, 0px))',
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                overflow: 'scroll',
            },
            [`&.${classes.desktop}`]: {
                //justifyContent: 'center',
                padding: theme.spacing(4),
                width: '33%',
                minWidth: '500px',
            },
            [`&.${classes.loading}`]: {
                backgroundImage: 'url(/images/pattern_animation.gif)',
            },
        },
        desktop: {},
        mobile: {},
        loading: {},
        background: {
            height: '100%',
            flexGrow: '1',
            backgroundImage: 'url(/images/bg_smaller_compressed.jpg)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            opacity: '0.80',
        },
        preloadLoadingAnimation: {
            backgroundImage: 'url(/images/pattern_animation.gif)',
            display: 'hidden',
        },
        logoWrapper: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: theme.spacing(4),
            [`&.${classes.desktop}`]: {
                justifyContent: 'flex-start',
                marginBottom: theme.spacing(10),
            },
        },
        logo: {
            '& path': {
                color: theme.palette.primaryDark.main,
                fill: theme.palette.primaryDark.main,
            },
        },
        welcomeText: {
            userSelect: 'none',
            color: theme.palette.primaryDark.main,
            paddingBottom: theme.spacing(4),
        },
        welcomeTextTitle: {
            paddingBottom: theme.spacing(1),
        },
    })
);

interface Props {
    isMobile: boolean;
    loading: boolean;
    children: JSX.Element | Array<JSX.Element>;
}

export default function LandingPageWrapper({
    isMobile,
    loading,
    children,
}: Props) {
    const { classes, cx } = useStyles();

    return (
        <div className={classes.base}>
            <div
                className={cx(classes.content, {
                    [classes.desktop]: !isMobile,
                    [classes.mobile]: isMobile,
                    [classes.loading]: loading,
                })}>
                <div
                    className={cx(classes.logoWrapper, {
                        [classes.desktop]: !isMobile,
                    })}>
                    <Logo className={classes.logo} />
                </div>
                {children}
            </div>
            {!isMobile ? <div className={classes.background} /> : undefined}
            <div className={classes.preloadLoadingAnimation} />
        </div>
    );
}

interface WelcomeTextProps {
    title: string;
    subtitle?: string;
}

export const WelcomeText = ({ title, subtitle }: WelcomeTextProps) => {
    const { classes } = useStyles();
    return (
        <>
            <div className={classes.welcomeText}>
                <Typography variant='h5' className={classes.welcomeTextTitle}>
                    <b>{title}</b>
                </Typography>
                {subtitle ? (
                    <Typography variant='body1'>{subtitle}</Typography>
                ) : null}
            </div>
        </>
    );
};
